import { visualization } from "@fscrypto/domain";
import {
  BarHorizontalInputs,
  BarHorizontalNormalizedInputs,
  BarHorizontalStackedInputs,
  BarInputs,
  BarLineInputs,
  BarNormalizedInputs,
  BarStackedInputs,
} from "@fscrypto/domain/visualization/v3";
import type { Options } from "highcharts";
import * as R from "remeda";
import { groupDataMulti, sumData } from "../../data";
import { Highcharts } from "../../lib/highcharts.client";
import { XYChartBuilder, mergeYAxisObjects, percentageFormatter } from "./xy-chart";

type Inputs =
  | BarInputs
  | BarStackedInputs
  | BarNormalizedInputs
  | BarHorizontalInputs
  | BarHorizontalStackedInputs
  | BarHorizontalNormalizedInputs;

export class BarOptionsBuilder extends XYChartBuilder<Inputs> {
  constructor(
    private input: Inputs,
    options: Options,
  ) {
    super(input, options);
  }

  get type() {
    return this.input.type;
  }

  build(data: any[]): Highcharts.Options {
    const config = super.build(data);
    const plotOptions = config.plotOptions?.[this.getHighchartsType()] ?? {};
    const isStacked = this.input.type === "bar-stacked" || this.input.type === "bar-horizontal-stacked";
    return {
      ...config,
      tooltip:
        isStacked && !this.options.tooltip
          ? { formatter: percentageFormatter(this.input.config.x?.type) }
          : this.options.tooltip,
      plotOptions: {
        ...(config.plotOptions ?? {}),
        [this.getHighchartsType()]: {
          ...plotOptions,
          stacking: this.#getStacking(),
          ...(this.options.plotOptions?.[this.getHighchartsType()] ?? {}),
        },
      },
    };
  }

  getHighchartsType() {
    if (visualization.v3.isBarHorizontal(this.input.type)) {
      return "bar";
    }
    return "column";
  }

  #getStacking() {
    if (this.input.type === "bar-stacked" || this.input.type === "bar-horizontal-stacked") {
      return "normal";
    }
    if (this.input.type === "bar-normalized" || this.input.type === "bar-horizontal-normalized") {
      return "percent";
    }
  }
}

export class BarLineOptionsBuilder extends XYChartBuilder<BarLineInputs> {
  constructor(
    private input: BarLineInputs,
    options: Options,
  ) {
    super(input, options);
  }

  get type() {
    return this.input.type;
  }

  build(data: any[]): Highcharts.Options {
    const config = super.build(data);

    const yAxis =
      this.input.config.line && this.input.config.y
        ? mergeYAxisObjects([...this.input.config.y, this.input.config.line], this.options.yAxis)
        : config.yAxis;

    const series = this.getSeries(data);
    return {
      ...config,
      yAxis,
      series: series as Highcharts.SeriesOptionsType[],
      plotOptions: {
        ...(config.plotOptions ?? {}),
        column: {
          ...config.plotOptions?.column,
          stacking: "normal",
        },
        line: config.plotOptions?.line ?? {},
      },
    };
  }

  public getData(data: any[]) {
    const { x, y, line, grouping } = this.inputs.config;
    if (!x || !y || !line) return data;
    let transformedData: any[] = data;
    if (grouping) {
      transformedData = groupDataMulti(x.key, [y[0].key, line.key], grouping.key, data);
    } else {
      transformedData = sumData(data, x.key, [...y.map((v) => v.key), line.key]);
    }
    const isReversed = (this.options.xAxis as Highcharts.XAxisOptions)?.reversed;
    if (visualization.v3.isDateType(x.type) && isReversed !== undefined) {
      transformedData = transformedData.map((d) => ({
        ...d,
        [x.key]: new Date(d[x.key]).getTime(),
      }));
      const sortedAsc = R.sortBy(transformedData, (d) => d[x.key]);
      return isReversed ? sortedAsc.reverse() : sortedAsc;
    }
    return transformedData;
  }

  getSeries(data: any[]) {
    const base = super.getSeries(data);
    const { x, y, line, grouping } = this.inputs.config;
    if (!x || !y || !line) return base;
    if (grouping) {
      const keys = R.pipe(
        data,
        R.flatMap((d) => Object.keys(d)),
        R.unique(),
        R.filter((key) => key !== x.key),
      ) as string[];
      return [
        ...keys.map((key) => {
          return {
            type: this.getHighchartsType(),
            data: data.map((d) => [d[x.key], Number(d[key]?.[y[0].key])]),
            name: `${key} ${y[0].key}`,
            color: this.inputs.config.colors?.[key],
            yAxis: y[0].position === "right" ? 1 : 0,
          };
        }),
        ...keys.map((key) => ({
          type: "line" as const,
          data: data.map((d) => [d[x.key], Number(d[key]?.[line.key])]),
          name: `${key} ${line.key}`,
          color: this.inputs.config.colors?.[key],
          showInLegend: false,
          yAxis: line.position === "right" ? 1 : 0,
        })),
      ];
    }
    return [
      ...base,
      {
        type: "line" as const,
        yAxis: line.position === "right" ? 1 : 0,
        data: data.map((d) => [d[x.key], Number(d[line.key])]),
        name: line.name ?? line.key,
        color: this.inputs.config.colors?.[line.key],
      },
    ];
  }

  getHighchartsType() {
    return "column" as const;
  }
}
