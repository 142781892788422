import { visualization } from "@fscrypto/domain";
import { Card, Input, Text } from "@fscrypto/ui";
import { FieldSelector } from "../FieldSelector";

interface HistogramFieldProps {
  fields: visualization.v3.ColumnTypePair[];
  viz: visualization.v3.Visualization;
  onChange: (i: visualization.v3.Visualization) => void;
}

export const HistogramFields = ({ fields, viz, onChange }: HistogramFieldProps) => {
  const { inputs, options } = viz.config;
  if (!visualization.v3.isHistogramInput(inputs)) return null;
  const { config } = inputs;

  const handleValueChange = ({ name, type }: visualization.v3.ColumnTypePair, remove?: boolean) => {
    if (remove) {
      const updated = visualization.v3.removeYKey(viz, name);
      onChange(updated);
      return;
    }
    const cleared = visualization.v3.clearYKeys(viz);
    const updated = visualization.v3.addYKey(cleared, name, type);
    onChange(updated);
  };

  const handleBinsChange = (bins: number) => {
    const updated = visualization.v3.updateHistogramBins(viz, bins);
    onChange(updated);
  };

  return (
    <>
      <Card className="space-y-2 p-1 px-2">
        <Text size="xs" weight="semi">
          Value Field
        </Text>
        <FieldSelector
          fields={fields}
          placeholder="Add Value Field"
          onChange={handleValueChange}
          value={config.y?.[0]?.key}
        />
      </Card>
      <Card className="space-y-2 p-1 px-2">
        <Text size="xs" weight="semi">
          Bins
        </Text>
        <div className="flex flex-col gap-y-1">
          <Input
            placeholder="Bins"
            value={options.plotOptions?.histogram?.binsNumber ?? 0}
            onChange={(e) => handleBinsChange(e.target.valueAsNumber)}
            min={0}
            type="number"
          />
        </div>
      </Card>
    </>
  );
};
