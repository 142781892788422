import { visualization } from "@fscrypto/domain";
import { Card, Text } from "@fscrypto/ui";
import { FieldSelector } from "../FieldSelector";

interface XYFieldsProps {
  fields: visualization.v3.ColumnTypePair[];
  viz: visualization.v3.Visualization;
  onChange: (i: visualization.v3.Visualization, options?: { changed: string }) => void;
}

export const XYFields = ({ fields, viz, onChange }: XYFieldsProps) => {
  const { inputs } = viz.config;
  if (!visualization.v3.isXYInput(inputs)) return null;
  const { config } = inputs;

  const handleXChange = ({ name, type }: visualization.v3.ColumnTypePair, remove = false) => {
    const updated = remove ? visualization.v3.removeXKey({ ...viz }) : visualization.v3.setXKey({ ...viz }, name, type);
    onChange(updated, { changed: "x" });
  };

  const handleYChange = ({ name, type }: visualization.v3.ColumnTypePair, remove = false) => {
    const updated = remove
      ? visualization.v3.removeYKey({ ...viz }, name)
      : visualization.v3.addYKey({ ...viz }, name, type);
    onChange(updated, { changed: "y" });
  };

  const handleCandlestickChange =
    (field: "high" | "low" | "open" | "close") =>
    (value: visualization.v3.ColumnTypePair, remove = false) => {
      const updated = visualization.v3.setCandlestickKey({ ...viz }, field, remove ? undefined : value);
      onChange(updated);
    };

  const handleGroupingChange = ({ name }: visualization.v3.ColumnTypePair, remove = false) => {
    const updated = remove ? visualization.v3.removeGroupingKey(viz) : visualization.v3.addGroupingKey(viz, name);
    onChange(updated, { changed: "grouping" });
  };

  const handleLineChange = ({ name, type }: visualization.v3.ColumnTypePair, remove = false) => {
    const updated = remove ? visualization.v3.removeBarLineKey(viz) : visualization.v3.addBarLineKey(viz, name, type);
    onChange(updated);
  };

  return (
    <>
      <Card className="space-y-2 p-1 px-2">
        <Text size="xs" weight="semi">
          X-Axis
        </Text>
        <FieldSelector fields={fields} placeholder="Add X-Axis Field" onChange={handleXChange} value={config.x?.key} />
      </Card>
      <Card className="min-h-[170px] space-y-2 p-1 px-2">
        {visualization.v3.isCandlestick(inputs) ? (
          <>
            <FieldSelector
              fields={fields}
              placeholder="Add Open Field"
              onChange={handleCandlestickChange("open")}
              value={inputs.config.open?.key}
            />
            <FieldSelector
              fields={fields}
              placeholder="Add High Field"
              onChange={handleCandlestickChange("high")}
              value={inputs.config.high?.key}
            />
            <FieldSelector
              fields={fields}
              placeholder="Add Low Field"
              onChange={handleCandlestickChange("low")}
              value={inputs.config.low?.key}
            />
            <FieldSelector
              fields={fields}
              placeholder="Add Close Field"
              onChange={handleCandlestickChange("close")}
              value={inputs.config.close?.key}
            />
          </>
        ) : (
          <>
            <Text size="xs" weight="semi">
              Y-Axis
            </Text>
            {!config.y ? (
              <FieldSelector fields={fields} placeholder="Add Y-Axis Field" onChange={handleYChange} />
            ) : (
              <>
                {config.y.map(({ key }, i) => (
                  <FieldSelector
                    key={`${key}-${i}`}
                    value={key}
                    fields={fields}
                    placeholder="Add Y-Axis Field"
                    onChange={handleYChange}
                  />
                ))}
                <FieldSelector
                  key={Math.random()}
                  fields={fields}
                  placeholder="Add Y-Axis Field"
                  onChange={handleYChange}
                />
              </>
            )}
          </>
        )}
      </Card>
      {visualization.v3.isBarLineInput(inputs) && (
        <Card className="space-y-2 p-1 px-2">
          <Text size="xs" weight="semi">
            Line Field
          </Text>
          <FieldSelector
            fields={fields}
            placeholder="Add Line Field"
            onChange={handleLineChange}
            value={inputs.config.line?.key}
          />
        </Card>
      )}
      {!visualization.v3.isCandlestick(inputs) && (
        <Card className="space-y-2 p-1 px-2">
          <Text size="xs" weight="semi">
            Grouping Field
          </Text>
          <FieldSelector
            fields={fields}
            placeholder="Add Grouping Field"
            onChange={handleGroupingChange}
            value={config.grouping?.key}
          />
        </Card>
      )}
    </>
  );
};
