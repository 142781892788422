import { visualization } from "@fscrypto/domain";
import { Input, Label } from "@fscrypto/ui";

interface Props {
  viz: visualization.v3.Visualization;
  onChange: (viz: visualization.v3.Visualization) => void;
}
export const TitleFormatting = ({ viz, onChange }: Props) => {
  const onTitleChange = (title: string) => {
    const updated = visualization.v3.updateTitle(viz, title);
    onChange(updated);
  };
  const onSubtitleChange = (subtitle: string) => {
    const updated = visualization.v3.updateSubtitle(viz, subtitle);
    onChange(updated);
  };
  const { config } = viz;
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-1">
        <Label htmlFor="title">Title</Label>
        <Input
          placeholder="Title"
          value={config.options.title?.text ?? ""}
          onChange={(e) => onTitleChange(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <Label htmlFor="subtitle">Subtitle</Label>
        <Input
          placeholder="Subtitle"
          value={config.options.subtitle?.text ?? ""}
          onChange={(e) => onSubtitleChange(e.target.value)}
        />
      </div>
    </div>
  );
};
