import { queryRun } from "@fscrypto/domain";
import { TableInputs } from "@fscrypto/domain/visualization/v3";
import { smallerTitleMargin } from "./util";

export class TableOptionsBuilder {
  constructor(
    public readonly inputs: TableInputs,
    public readonly options: Highcharts.Options = {},
    public readonly results: any[] = [],
  ) {}

  build(data: queryRun.QueryRunResult) {
    const { columns, csvData, types } = filterCsvData(data, this.inputs.config);

    return {
      ...this.options,
      title: smallerTitleMargin(this.options.title),
      table: { ...data, csvData, columns, types },
    };
  }

  getData(data: queryRun.QueryRunResult) {
    // old visualizatiosn dont have all the data in the correct format so we re-create it here for backwards compatibility
    if (!!data.jsonData.length && !data.csvData) {
      const csvData = data.jsonData.map((row: any) => Object.values(row));
      const columns = Object.keys(data.jsonData[0]);
      return { ...data, csvData, columns, types: [], resultsRetrieved: true };
    }
    return data;
  }

  getDataKeys(): string[] {
    return [];
  }

  getSeries() {
    const record = this.inputs.config;
    return record;
  }
}

export interface TableOptions {
  columns: { title: string; visible: boolean }[];
  rows: { title: string; value: string }[][];
}

function filterCsvData(
  queryResult: queryRun.QueryRunResult,
  config: TableInputs["config"],
): {
  columns: string[];
  csvData: queryRun.QueryRunResult["csvData"];
  types: queryRun.QueryRunResult["types"];
} {
  // Determine the indices of columns to keep and extract custom labels
  const visibleColumns = queryResult.columns
    .map((column, index) => ({
      column,
      index,
      customLabel: config.columnOptions[column]?.customLabel || column, // Use custom label if available, otherwise use column name
    }))
    .filter(({ column }) => config.columnOptions[column] && config.columnOptions[column].visible)
    .sort((a, b) => config.columnOptions[a.column].position - config.columnOptions[b.column].position);

  // Filter and reorder the CSV data based on visible columns
  const filteredCsvData = queryResult.csvData.map((row) => visibleColumns.map(({ index }) => row[index]));

  // Filter and reorder the types based on visible columns
  const filteredTypes = visibleColumns.map(({ index }) => queryResult.types[index]);

  // Use custom labels for columns in the output if specified
  return {
    columns: visibleColumns.map(({ customLabel }) => customLabel),
    csvData: filteredCsvData,
    types: filteredTypes,
  };
}
