import { visualization } from "@fscrypto/domain";
import { Card, Text } from "@fscrypto/ui";
import { FieldSelector } from "../FieldSelector";

interface XYFieldsProps {
  fields: visualization.v3.ColumnTypePair[];
  viz: visualization.v3.Visualization;
  onChange: (i: visualization.v3.Visualization) => void;
}

export const ScatterFields = ({ fields, viz, onChange }: XYFieldsProps) => {
  const { inputs } = viz.config;
  if (!visualization.v3.isScatterInput(inputs)) return null;
  const { config } = inputs;

  const handleZChange = ({ name, type }: visualization.v3.ColumnTypePair, remove = false) => {
    const updated = remove ? visualization.v3.removeZKey({ ...viz }) : visualization.v3.setZKey({ ...viz }, name, type);
    onChange(updated);
  };

  return (
    <Card className="space-y-2 p-1 px-2">
      <Text size="xs" weight="semi">
        Marker Size
      </Text>
      <FieldSelector
        fields={fields}
        placeholder="Add Marker Size Field"
        onChange={handleZChange}
        value={config.z?.key}
      />
    </Card>
  );
};
