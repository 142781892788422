import { type AreaInputs, type AreaNormalizedInputs, type AreaStackedInputs } from "@fscrypto/domain/visualization/v3";
import type { Options } from "highcharts";
import { XYChartBuilder, percentageFormatter } from "./xy-chart";

type Inputs = AreaInputs | AreaStackedInputs | AreaNormalizedInputs;

export class AreaOptionsBuilder extends XYChartBuilder<Inputs> {
  constructor(
    public readonly input: Inputs,
    public readonly options: Options,
  ) {
    super(input, options);
  }
  get type() {
    return this.input.type;
  }

  getHighchartsType(): keyof Highcharts.PlotOptions {
    return "area";
  }

  build(data: any[]): Highcharts.Options {
    const config = super.build(data);
    const isStacked = this.input.type === "area-stacked";
    return {
      ...config,
      tooltip:
        isStacked && !this.options.tooltip
          ? { formatter: percentageFormatter(this.input.config.x?.type) }
          : this.options.tooltip,
      plotOptions: {
        ...(config.plotOptions ?? {}),
        area: {
          ...(config.plotOptions?.area ?? {}),
          opacity: 0.8,
          marker: {
            enabled: false,
          },
          linecap: "round",
          stacking: this.#getStacking(),
          ...(this.options.plotOptions?.area ?? {}),
        },
      },
    };
  }

  #getStacking() {
    if (this.input.type === "area-stacked") {
      return "normal";
    }
    if (this.input.type === "area-normalized") {
      return "percent";
    }
  }
}
