import { BigNumberInputs, Options } from "@fscrypto/domain/visualization/v3";
import { smallerTitleMargin } from "./util";

export class BigNumberOptionsBuilder {
  constructor(
    public readonly inputs: BigNumberInputs,
    public readonly options: Highcharts.Options = {},
  ) {}

  build(vals: Options["bigNumber"]) {
    return {
      ...this.options,
      title: smallerTitleMargin(this.options.title),
      bigNumber: vals,
    };
  }

  getData(data: any[]): Options["bigNumber"] {
    const { valueKey, rowNumber, caption, prefix, suffix, decimals, d3Format, autoFormat } = this.inputs.config;
    if (!valueKey)
      return {
        primaryValue: undefined,
        secondaryValue: undefined,
        rows: data.length,
        prefix,
        suffix,
        decimals,
        d3Format,
        autoFormat,
        caption,
      };
    const primaryValue = data[(rowNumber ?? 1) - 1]?.[valueKey!];
    // const secondaryValue = data[(secondaryRowNumber ?? 1) - 1][secondaryValueKey!];
    return {
      primaryValue: primaryValue ? Number(primaryValue) : undefined,
      rows: data.length,
      prefix,
      suffix,
      decimals,
      d3Format,
      autoFormat,
      caption,
    };
  }

  getDataKeys(data: Options["bigNumber"]) {
    return [data?.secondaryValue, data?.secondaryValue] as unknown as string[];
  }

  getSeries() {
    const record = this.inputs.config;
    return record;
  }
}
