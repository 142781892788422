import { visualization } from "@fscrypto/domain";
import { Card, Text } from "@fscrypto/ui";
import { FieldSelector } from "../FieldSelector";

interface PieFieldsProps {
  fields: visualization.v3.ColumnTypePair[];
  viz: visualization.v3.Visualization;
  onChange: (i: visualization.v3.Visualization) => void;
}

export const PieFields = ({ fields, viz, onChange }: PieFieldsProps) => {
  const { inputs } = viz.config;
  if (!visualization.v3.isPieInput(inputs)) return null;
  const { config } = inputs;

  const handleSliceChange = ({ name, type }: visualization.v3.ColumnTypePair) => {
    const updated = visualization.v3.setSliceKey({ ...viz }, name, type);
    onChange(updated);
  };

  const handleValueChange = ({ name, type }: visualization.v3.ColumnTypePair) => {
    const updated = visualization.v3.setValueKey({ ...viz }, name, type);
    onChange(updated);
  };

  return (
    <>
      <Card className="min-h-[170px] space-y-2 p-1 px-2">
        <Text size="xs" weight="semi">
          Slice
        </Text>
        <FieldSelector
          fields={fields}
          placeholder="Add Slice Field"
          onChange={handleSliceChange}
          value={config.slice?.key}
        />
      </Card>
      <Card className="space-y-2 p-1 px-2">
        <Text size="xs" weight="semi">
          Value
        </Text>
        <FieldSelector
          fields={fields}
          placeholder="Add Value Field"
          onChange={handleValueChange}
          value={config.value?.key}
        />
      </Card>
    </>
  );
};
