import { visualization } from "@fscrypto/domain";
import { Dropdown } from "@fscrypto/ui";
import { VizIcon } from "../icons/viz-icon";
import { Visualization, VisualizationType } from "@fscrypto/domain/visualization/v3";
import { ChevronDown } from "lucide-react";

interface ChangeTypeDropdownProps {
  viz: visualization.v3.Visualization;
  onChange: (newViz: Visualization) => void;
}
export const ChangeTypeDropdown = ({ viz, onChange }: ChangeTypeDropdownProps) => {
  const availableConversions = visualization.v3.ChartTypeConverter.getValidChartTypes(viz.config.inputs.type);

  const handleChoose = (t: VisualizationType) => {
    const converted = new visualization.v3.ChartTypeConverter(viz).convert(t);
    onChange(converted);
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger className="flex w-full items-center">
        <VizIcon type={viz.config.inputs.type} size="xs" label variant="button" />
        <ChevronDown size={16} />
      </Dropdown.Trigger>
      <Dropdown.Content>
        {availableConversions.map((t) => (
          <Dropdown.Item key={t}>
            <VizIcon type={t} size="xs" variant="button" label onClick={() => handleChoose(t)} />
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
