import { visualization } from "@fscrypto/domain";
import { CandlestickInputs } from "@fscrypto/domain/visualization/v3";
import type { Options } from "highcharts";
import * as R from "remeda";
import { averageData } from "../../data";
import { XYChartBuilder } from "./xy-chart";

export class CandlestickOptionsBuilder extends XYChartBuilder<CandlestickInputs> {
  constructor(
    public readonly input: CandlestickInputs,
    public readonly options: Options,
  ) {
    super(input, options);
  }
  get type() {
    return this.input.type;
  }

  getHighchartsType(): keyof Highcharts.PlotOptions {
    return "candlestick";
  }

  getData(data: any[]) {
    const { x, open, close, colors, high, low } = this.inputs.config;
    if (!x || !open || !close || !colors || !high || !low) return data;
    if (!visualization.v3.isDateType(x.type)) return data;
    const groupedByDate = averageData(data, x.key, [open.key, high.key, low.key, close.key]);
    const retval = groupedByDate.map((d: Record<string, unknown>) => [
      new Date(d[x.key] as string),
      d[open.key],
      d[high.key],
      d[low.key],
      d[close.key],
    ]);
    return retval;
  }

  getSeries(data: any[]): Highcharts.SeriesCandlestickOptions[] {
    const yAxes = this.options.yAxis
      ? Array.isArray(this.options.yAxis)
        ? this.options.yAxis
        : [this.options.yAxis]
      : [];
    return [
      {
        type: "candlestick",
        data: data,
        name: yAxes[0]?.title?.text ?? this.input.config.open?.key ?? this.options.title?.text,
      },
    ];
  }

  build(data: any[]): Highcharts.Options {
    const config = super.build(data);
    return R.mergeDeep(
      {
        xAxis: {
          ordinal: false,
        },
        plotOptions: {
          candlestick: {
            color: this.inputs.config.colors?.downwardTrend,
            lineColor: this.inputs.config.colors?.downwardTrend,
            upColor: this.inputs.config.colors?.upwardTrend,
            upLineColor: this.inputs.config.colors?.upwardTrend,
          },
        },
      } satisfies Highcharts.Options,
      config as Record<string, unknown>,
    );
  }
}
