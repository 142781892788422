import { visualization } from "@fscrypto/domain";
import { Card, Checkbox, Input, ScrollArea, Text } from "@fscrypto/ui";
import { Reorder, useDragControls } from "framer-motion";
import { GripVerticalIcon } from "lucide-react";
import { useEffect } from "react";

interface TableFieldsProps {
  fields: visualization.v3.ColumnTypePair[];
  viz: visualization.v3.Visualization;
  onChange: (i: visualization.v3.Visualization) => void;
}

export const TableFields = ({ fields, viz, onChange }: TableFieldsProps) => {
  useEffect(() => {
    const newViz = visualization.v3.selectAllIfEmpty(viz, fields);
    if (newViz) {
      onChange(newViz);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!visualization.v3.isTableInput(viz.config.inputs)) return null;
  const config = viz.config.inputs.config;

  const handleVisibilityChange = (columnName: string, isVisible: boolean) => {
    const newViz = visualization.v3.updateColumnVisibility(viz, { columnName, isVisible });
    onChange(newViz);
  };
  const handleColumnLabelChange = (columnName: string, label: string) => {
    const newViz = visualization.v3.updateCustomColumnLabel(viz, { columnName, label });
    onChange(newViz);
  };

  const handleReorder = (newOrder: string[]) => {
    const newViz = visualization.v3.reorderColumns(viz, newOrder);
    onChange(newViz);
  };

  const toggleSelectAll = () => {
    const newViz = visualization.v3.toggleSelectAll(viz, fields);
    onChange(newViz);
  };

  const sortedFields = visualization.v3.sortFields(fields, config);

  const allSelected = visualization.v3.areAllColumnsSelected(viz, fields);

  return (
    <>
      <Card className="max-h-full space-y-2 p-1 px-2">
        <Text size="xs" weight="semi">
          Columns
        </Text>
        <div className="flex w-full items-center gap-x-3">
          <Checkbox checked={allSelected} onCheckedChange={toggleSelectAll} />
          <Text className="w-1/2 text-xs font-semibold">Select All</Text>
        </div>
        <ScrollArea className="h-full">
          <Reorder.Group
            axis="y"
            values={sortedFields.map((f) => f.name)}
            onReorder={handleReorder}
            className="flex w-full flex-col gap-y-2 pb-6 pt-2"
          >
            {sortedFields.map((field) => (
              <DragItem
                key={field.name}
                name={field.name}
                checked={config.columnOptions[field.name]?.visible || false}
                customLabel={config.columnOptions[field.name]?.customLabel || ""}
                handleVisibilityChange={handleVisibilityChange}
                handleLabelChange={handleColumnLabelChange}
              />
            ))}
            <div className="h-12"></div>
          </Reorder.Group>
        </ScrollArea>
      </Card>
    </>
  );
};

interface DragItem {
  name: string;
  checked: boolean;
  customLabel?: string;
  handleVisibilityChange: (columnName: string, isVisible: boolean) => void;
  handleLabelChange: (columnName: string, label: string) => void;
}
const DragItem = ({ checked, name, customLabel, handleVisibilityChange, handleLabelChange }: DragItem) => {
  const dragControls = useDragControls();

  return (
    <div className="">
      <Reorder.Item
        key={name}
        value={name}
        className="bg-background flex w-full items-center rounded border p-2"
        dragControls={dragControls}
        dragListener={false}
      >
        <div
          className="draggable-handle bg-muted flex h-8 items-center justify-center rounded border shadow-sm"
          style={{ cursor: "grab" }}
          onPointerDown={(event) => {
            event.stopPropagation();
            dragControls.start(event);
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <GripVerticalIcon className="size-6 text-muted-foreground" />
        </div>
        <div className="ml-2 flex w-full select-none items-center gap-x-3">
          <Checkbox
            checked={checked || false}
            onCheckedChange={(checked: boolean) => handleVisibilityChange(name, checked)}
          />
          <Text className="w-1/2 text-xs font-semibold">{name}</Text>
          <Input
            className="flex-1 text-xs"
            value={customLabel || ""}
            onChange={(e) => handleLabelChange(name, e.target.value)}
          />
        </div>
      </Reorder.Item>
    </div>
  );
};
