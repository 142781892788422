import { visualization } from "@fscrypto/domain";
import { Button, Popover, Text, cn } from "@fscrypto/ui";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@fscrypto/ui/command";
import {
  CalendarClockIcon,
  CheckIcon,
  ChevronDown,
  CircleFadingPlusIcon,
  CircleHelp,
  FileJson,
  HashIcon,
  Trash2Icon,
  TypeIcon,
} from "lucide-react";
import { useState } from "react";

interface FieldSelectorProps {
  fields: visualization.v3.ColumnTypePair[];
  placeholder: string;
  value?: string;
  onChange: (field: visualization.v3.ColumnTypePair, remove?: boolean) => void;
}

export const FieldSelector = ({ fields, placeholder = "Select...", onChange, value }: FieldSelectorProps) => {
  const [open, setOpen] = useState(false);
  const selectedField = fields.find((field) => field.name === value);

  const handleSelect = (name: string) => {
    const newField = fields.find((field) => field.name === name);
    if (!newField) return;
    if (selectedField?.name === newField.name) {
      onChange(selectedField, true);
    } else {
      const field = fields.find((field) => field.name === newField.name);
      if (!field) {
        throw new Error("Field not found");
      }
      onChange(newField);
    }
    setOpen(false);
  };
  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        {selectedField ? (
          <Button variant="outline" className="w-full justify-between">
            <FieldTypeIcon type={selectedField.type} />
            {selectedField.name}
            <div className="flex items-center gap-x-2">
              <Trash2Icon
                className="size-4 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(selectedField, true);
                }}
              />

              <ChevronDown className="h-4 w-4" />
            </div>
          </Button>
        ) : (
          <Button
            variant="ghost"
            role="combobox"
            aria-expanded={open}
            className="text-muted-foreground/50 w-full justify-start border border-dashed"
          >
            <CircleFadingPlusIcon className=" mr-2 h-4 w-4" />
            {placeholder}
          </Button>
        )}
      </Popover.Trigger>
      <Popover.Content className="" sideOffset={-36} side="bottom">
        <Command>
          <CommandInput placeholder="Search fields..." className="h-9" />
          <CommandEmpty>No Field found</CommandEmpty>
          <CommandList></CommandList>
          <CommandGroup>
            {fields.map((field) => (
              <CommandItem key={field.name} value={field.name} onSelect={handleSelect}>
                <FieldTypeIcon type={field.type} />
                <Text>{field.name}</Text>
                <CheckIcon
                  className={cn("ml-auto h-4 w-4", selectedField?.name === field.name ? "opacity-100" : "opacity-0")}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </Popover.Content>
    </Popover.Root>
  );
};

const FieldTypeIcon = ({ type }: { type?: string }) => {
  switch (type) {
    case "text":
      return <TypeIcon className="size-4 text-primary/40 mr-2" />;
    case "fixed":
      return <HashIcon className="size-4 text-primary/40 mr-2" />;
    case "timestamp_ntz":
    case "date":
    case "timestamp_ltz":
      return <CalendarClockIcon className="size-4 text-primary/40 mr-2" />;
    case "real":
      return <HashIcon className="size-4 text-primary/40 mr-2" />;
    case "object":
      return <FileJson className="size-4 text-primary/40 mr-2" />;
    default:
      return <CircleHelp className="size-4 text-primary/40 mr-2" />;
  }
};
