import * as R from "remeda";

/**
 * Example:
 *
 * data = [{ day: "monday", price: 1, stock: 100 }, { day: "monday", price: 1, stock: 100 }, { day: "tuesday", price: 1, stock: 100 }]
 *
 * x = "day"
 *
 * y = ["price", "stock"]
 *
 * result = [{ day: "monday", price: 2, stock: 200 }, { day: "tuesday", price: 1, stock: 100 }]
 */
export const sumData = (data: any[], x: string, ys: string[]) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x]),
    R.values(),
    R.map((d) =>
      R.pipe(
        d,
        R.reduce(
          (acc, d) => {
            const obj = { ...acc, [x]: d[x] };
            for (const y of ys) {
              obj[y] = (acc[y] ?? 0) + d[y];
            }
            return obj;
          },
          {} as Record<string, any>,
        ),
      ),
    ),
  );
};

export const countData = (data: any[], x: string) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x]),
    R.values(),
    R.map((d) =>
      R.pipe(
        d,
        R.reduce((acc, d) => ({ ...acc, [x]: d[x], count: (acc.count ?? 0) + 1 }), {} as Record<string, any>),
      ),
    ),
  );
};

/**
 * Example:
 *
 * data = [{ day: "monday", price: 0, stock: 0 }, { day: "monday", price: 5, stock: 500 }, { day: "tuesday", price: 1, stock: 100 }]
 *
 * x = "day"
 *
 * y = ["price", "stock"]
 *
 * result = [{ day: "monday", price: 2.5, stock: 250 }, { day: "tuesday", price: 1, stock: 100 }]
 */
export const averageData = (data: any[], x: string, ys: string[]) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x]),
    R.values(),
    R.map((d) =>
      R.pipe(
        d,
        R.reduce(
          (acc, d) => {
            const obj = { ...acc, [x]: d[x] };
            for (const y of ys) {
              obj[y] = (acc[y] ?? 0) + d[y];
              obj[`count-${y}`] = (acc[`count-${y}`] ?? 0) + 1;
            }
            return obj;
          },
          {} as Record<string, any>,
        ),
      ),
    ),
    R.map((d) => R.reduce(ys, (acc, y) => ({ ...acc, [x]: d[x], [y]: d[y] / d[`count-${y}`] }), {})),
  );
};

/**
 * Example:
 *
 * x = "day"
 *
 * y = "place"
 *
 * key = "time"
 *
 * data = [{ day: "monday", place: "first", time: "morning" }, { day: "monday", place: "last", time: "night" }, { day: "tuesday", place: "first", time: "morning" }, { day: "tuesday", place: "last", time: "morning" }]
 *
 * result = [{ day: "monday", morning: "first", night: "last" }, { day: "tuesday", morning: "last" }]
 */
export const groupData = <T extends Record<string, unknown>>(x: keyof T, y: keyof T, key: keyof T, data: T[]) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x] as string),
    R.values(),
    R.map((d) =>
      R.pipe(
        d,
        R.map((d) => ({ [x]: d[x], [d[key] as string]: d[y] })),
        R.reduce((acc, x) => R.merge(acc, x), {} as Record<string, any>),
      ),
    ),
  );
};

/**
 * Example:
 *
 * x = "date"
 *
 * ys = ["usage", "rank"]
 *
 * key = "browser"
 *
 * data = [ { date: "1/1", browser: "firefox", usage: 0.2, rank: 1 },
 *
 *   { date: "1/1", browser: "chrome", usage: 0.8, rank: 2 },
 *
 *   { date: "1/2", browser: "firefox", usage: 0.7, rank: 2 },
 *
 *   { date: "1/2", browser: "chrome", usage: 0.3, rank: 1 }]
 *
 * result = [ { date: "1/1", "firefox": { usage: 0.2, rank: 1 }, "chrome": { usage: 0.8, rank: 2 } },
 *
 *   { date: "1/2", "firefox": { usage: 0.7, rank: 2 }, "chrome": { usage: 0.3, rank: 1 } } ]
 */
export const groupDataMulti = <T extends Record<string, unknown>>(
  x: keyof T,
  ys: (keyof T)[],
  key: keyof T,
  data: T[],
) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x] as string),
    R.values(),
    R.map((d) =>
      R.pipe(
        d,
        R.map((d) => ({ [x]: d[x], [d[key] as string]: Object.fromEntries(ys.map((y) => [y, d[y]])) })),
        R.reduce((acc, x) => R.merge(acc, x), {} as Record<string, any>),
      ),
    ),
  );
};
