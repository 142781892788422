import { type HistogramInputs } from "@fscrypto/domain/visualization/v3";
import { XYChartBuilder } from "./xy-chart";
import { categorical } from "../../colors/default";

export class HistogramOptionsBuilder extends XYChartBuilder<HistogramInputs> {
  type = "histogram" as const;
  constructor(input: HistogramInputs, config: Highcharts.Options) {
    super(input, config);
  }

  getHighchartsType() {
    return "histogram" as const;
  }

  getSeries(data: any[]) {
    const { y } = this.inputs.config;
    if (!y?.length) return [];
    const [series] = y.map(({ key, name }) => ({
      type: this.getHighchartsType(),
      data: data.map((d) => Number(d[key])),
      color: this.inputs.config.colors?.[key],
      name: name ?? key,
    }));
    return [
      {
        type: "histogram" as const,
        name: series.name,
        xAxis: 0,
        yAxis: 0,
        baseSeries: 1,
        color: series.color,
      },
      { ...series, visible: false, showInLegend: false },
    ];
  }

  getData(data: any[]) {
    const { y } = this.inputs.config;
    if (!y?.length) return data;
    const valueKey = y[0].key;
    return data.map((d) => ({ [valueKey]: d[valueKey] }));
  }

  build(data: any[]) {
    const series = this.getSeries(data);
    return {
      ...this.options,
      series,
      colors: this.options.colors ?? categorical,
      xAxis: {
        title: {
          text: this.inputs.config.x?.name,
        },
        alignTicks: false,
        ...this.options.xAxis,
      },
      yAxis: {
        title: {
          text: "Frequency",
        },
        ...this.options.yAxis,
      },
      plotOptions: {
        ...this.options.plotOptions,
        histogram: {
          accessibility: {
            point: {
              valueDescriptionFormat: "{index}. {point.x:.3f} to " + "{point.x2:.3f}, {point.y}.",
            },
          },
          ...this.options.plotOptions?.histogram,
        },
      },
    } as Highcharts.Options;
  }
}
