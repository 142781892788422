import { visualization } from "@fscrypto/domain";
import { Checkbox, Input, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";

export const YAxisFormatting = ({
  yConfig,
  onYAxisLabelChange,
  onYAxisPositionChange,
  onLogScaleChange,
}: {
  yConfig: {
    label: string;
    position: "left" | "right" | undefined;
    logScale: boolean;
  };
  onYAxisLabelChange: (label: string) => void;
  onLogScaleChange: (checked: boolean) => void;
  onYAxisPositionChange: (position: "left" | "right") => void;
}) => (
  <div className="flex flex-col gap-y-4">
    <div className="flex flex-col gap-y-1">
      <Label htmlFor="title">Label</Label>
      <Input value={yConfig.label} onChange={(e) => onYAxisLabelChange(e.target.value)} />
    </div>
    <div className="flex flex-col gap-y-1">
      <Label htmlFor="subtitle">Axis Position</Label>
      <Select value={yConfig.position ?? "left"} onValueChange={onYAxisPositionChange}>
        <SelectTrigger className="">
          <SelectValue placeholder="None" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="left">Left</SelectItem>
          <SelectItem value="right">Right</SelectItem>
        </SelectContent>
      </Select>
    </div>
    <div className="flex gap-x-1">
      <Checkbox onCheckedChange={onLogScaleChange} checked={yConfig.logScale} id="scale" />
      <Label htmlFor="scale">Log Scale</Label>
    </div>
  </div>
);

export const YAxisFormattingXY = ({
  axis,
  yConfig,
  viz,
  onChange,
}: {
  axis: { type: "array"; index: number } | { type: "barLine" };
  yConfig: visualization.v3.Y;
  viz: visualization.v3.Visualization;
  onChange: (viz: visualization.v3.Visualization) => void;
}) => (
  <YAxisFormatting
    yConfig={{
      label: yConfig.name ?? yConfig.key,
      logScale: yConfig.logScale ?? false,
      position: yConfig.position,
    }}
    onYAxisLabelChange={(label) => {
      const updated = visualization.v3.updateYAxisLabel(viz, axis, label);
      onChange(updated);
    }}
    onYAxisPositionChange={(value) => {
      const updated = visualization.v3.updateYAxisPosition(viz, axis, value);
      onChange(updated);
    }}
    onLogScaleChange={(checked) => {
      const updated = visualization.v3.updateYAxisScale(viz, axis, checked);
      onChange(updated);
    }}
  />
);

export const YAxisFormattingCandlestick = ({
  yAxis,
  viz,
  onChange,
}: {
  yAxis: Highcharts.Options["yAxis"];
  viz: visualization.v3.Visualization;
  onChange: (viz: visualization.v3.Visualization) => void;
}) => {
  const firstYAxis = Array.isArray(yAxis) ? yAxis[0] : yAxis;
  return (
    <YAxisFormatting
      yConfig={{
        label: firstYAxis?.title?.text ?? "",
        logScale: firstYAxis?.type === "logarithmic",
        position: firstYAxis?.opposite === false ? "left" : "right",
      }}
      onYAxisLabelChange={(label) => {
        onChange(
          visualization.v3.updateOptions(viz, {
            ...viz.config.options,
            yAxis: {
              ...firstYAxis,
              title: { ...firstYAxis?.title, text: label },
            } as visualization.v3.Visualization["config"]["options"],
          }),
        );
      }}
      onYAxisPositionChange={(value) => {
        onChange(
          visualization.v3.updateOptions(viz, {
            ...viz.config.options,
            yAxis: {
              ...firstYAxis,
              opposite: value !== "left",
            } as visualization.v3.Visualization["config"]["options"],
          }),
        );
      }}
      onLogScaleChange={(checked) => {
        onChange(
          visualization.v3.updateOptions(viz, {
            ...viz.config.options,
            yAxis: {
              ...firstYAxis,
              type: checked ? "logarithmic" : undefined,
            } as visualization.v3.Visualization["config"]["options"],
          }),
        );
      }}
    />
  );
};
