import { visualization } from "@fscrypto/domain";
import Highcharts, { XAxisOptions, YAxisOptions } from "highcharts";
import { compact, merge } from "lodash-es";
import * as R from "remeda";
import { categorical } from "../../colors/default";
import { groupData, sumData } from "../../data";
import { smallerTitleMargin } from "../util";

export abstract class XYChartBuilder<I extends visualization.v3.XYInputs> {
  constructor(
    public readonly inputs: I,
    public readonly options: Highcharts.Options = {},
  ) {}

  abstract get type(): I["type"];
  abstract getHighchartsType(): keyof Highcharts.PlotOptions;

  public getSeries(data: any[]): Highcharts.SeriesOptions[] {
    const { x, y, grouping } = this.inputs.config;
    if (!x || !y) return [];
    if (grouping) {
      const keys = R.pipe(
        data,
        R.flatMap((d) => Object.keys(d)),
        R.unique(),
        R.filter((key) => key !== x.key),
      ) as string[];

      return keys.map((key) => {
        return {
          type: this.getHighchartsType(),
          data: data.map((d) => [d[x.key], Number(d[key])]),
          color: this.inputs.config.colors?.[key],
          name: key,
        };
      });
    }
    return y.map(({ key, name, position = "left" }) => {
      return {
        type: this.getHighchartsType(),
        data: data.map((d) => [d[x.key], Number(d[key])]),
        color: this.inputs.config.colors?.[key],
        yAxis: position === "right" ? 1 : 0,
        name: name ?? key,
      };
    });
  }

  public getData(data: any[]) {
    const { x, y, grouping } = this.inputs.config;
    if (!x || !y) return data;
    let transformedData: any[] = data;
    if (grouping) {
      transformedData = groupData(x.key, y[0].key, grouping.key, data);
    } else {
      transformedData = sumData(
        data,
        x.key,
        y.map((v) => v.key),
      );
    }
    const isReversed = (this.options.xAxis as Highcharts.XAxisOptions)?.reversed;
    if (visualization.v3.isDateType(x.type) && isReversed !== undefined) {
      transformedData = transformedData.map((d) => ({
        ...d,
        [x.key]: new Date(d[x.key]).getTime(),
      }));
      const sortedAsc = R.sortBy(transformedData, (d) => d[x.key]);
      return isReversed ? sortedAsc.reverse() : sortedAsc;
    }
    return R.sortBy(transformedData, (x) => x.key);
  }

  public getDataKeys(data: any[]) {
    const allKeys = R.pipe(
      data,
      R.flatMap(Object.keys),
      R.unique(),
      R.filter((k) => k !== this.inputs.config.x?.key),
    );
    return allKeys;
  }

  public build(data: any[]): Highcharts.Options {
    const { x, y } = this.inputs.config;
    if (!x)
      return {
        ...this.options,
      };
    const baseOptions = {};
    const xOptions = this.options.xAxis as XAxisOptions | undefined;
    const yOptions = this.options.yAxis;
    const isDate = visualization.v3.isDateType(x.type);
    const isNumber = visualization.v3.isNumberType(x.type);
    let dateOptions = {};
    if (isDate) {
      dateOptions = {
        pointStart: 0,
        pointInterval: 1,
        relativeXValue: true,
      };
    } else {
      dateOptions = {};
    }
    const mergedYOptions = mergeYAxisObjects(y ?? [], yOptions);
    const series = this.getSeries(data);
    return {
      series,
      ...this.options,
      title: smallerTitleMargin(this.options.title),
      xAxis: merge(
        {
          title: {
            text: xOptions?.title?.text ?? x.name ?? x.key,
            style: {
              fontSize: "12",
            },
          },
          type: visualization.v3.isDateType(x.type) ? "datetime" : "linear",
          categories: !isDate && !isNumber ? compact(data.map((d) => d[x.key]?.toString())) : undefined,
        },
        xOptions,
      ),
      plotOptions: {
        series: merge(dateOptions, this.options.plotOptions?.series),
        [this.getHighchartsType()]: R.mergeAll([
          baseOptions,
          this.options.plotOptions?.[this.getHighchartsType()] ?? {},
        ]),
      },
      yAxis: mergedYOptions,
      colors: this.options.colors ?? categorical,
    } as Highcharts.Options;
  }
}

export const mergeYAxisObjects = (
  yInputs: visualization.v3.Y[],
  yConfig: YAxisOptions | YAxisOptions[] | undefined,
): YAxisOptions | YAxisOptions[] | undefined => {
  const yConfigs = Array.isArray(yConfig) ? yConfig : yConfig ? [yConfig] : [];

  const leftInputs = yInputs.filter((axis) => !axis.position || axis.position === "left");
  const rightInputs = yInputs.filter((axis) => axis.position === "right");

  const leftTitle: { title: Highcharts.YAxisTitleOptions } = {
    title: {
      text: yConfigs[0]?.title?.text ?? leftInputs.map((a) => a.name ?? a.key).join(", "),
      style: {
        fontSize: "12",
      },
    },
  };
  const rightTitle: { title: Highcharts.YAxisTitleOptions } = {
    title: {
      text: rightInputs.map((a) => a.name ?? a.key).join(", "),
      style: {
        fontSize: "12",
      },
    },
  };

  const leftScaleLog = leftInputs.some((a) => a.logScale);
  const rightScaleLog = rightInputs.some((a) => a.logScale);

  return [
    merge(
      leftTitle,
      leftScaleLog
        ? {
            type: "logarithmic",
          }
        : undefined,
      yConfigs[0] ?? {},
    ) as YAxisOptions,
    merge(
      rightTitle,
      rightScaleLog
        ? {
            type: "logarithmic",
          }
        : undefined,
      yConfigs[1] ?? {},
      { opposite: true },
    ) as YAxisOptions,
  ] satisfies YAxisOptions[];
};

export const percentageFormatter = (xAxisType: string | undefined) => {
  return function (this: Highcharts.TooltipFormatterContextObject) {
    const points = this.points;
    if (!points) return "chart data is empty";
    return [
      `<b>${visualization.v3.isDateType(xAxisType ?? "text") ? Highcharts.dateFormat("%A, %e %b, %H:%M", this.x as number) : this.x}</b><br/>`,
      ...points.map(
        (p) =>
          `<span style="color:${p.series.color}">` +
          `${p.series.name}: ` +
          `<b>${p.y}</b> ` +
          `${p.percentage.toFixed(2)}%` +
          `</span><br/>`,
      ),
    ];
  };
};
