export const categorical = [
  "#04e1cb",
  "#1b96ff",
  "#ba01ff",
  "#baec70",
  "#ff5d2d",
  "#ac8e68",
  "#ffe620",
  "#e3066a",
  "#c9c9c9",
];
