import { queryRun } from "@fscrypto/domain";
import type { TitleOptions } from "highcharts";
import * as R from "remeda";

export const smallerTitleMargin = (existingOptions?: TitleOptions): TitleOptions => ({
  margin: -10,
  ...existingOptions,
});

export const createFields = (results: queryRun.QueryRunResult) => {
  const fields = R.zip(results?.columns ?? [], results?.types ?? []).map(([name, type]) => ({ name, type }));
  return fields;
};

export const stringifyUnsupportedKeys = (data: Record<string, unknown>[], keys: string[]) =>
  data.map((d) => ({ ...d, ...Object.fromEntries(keys.map((key) => [key, JSON.stringify(d[key])])) }));
