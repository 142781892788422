import { visualization } from "@fscrypto/domain";
import { QueryRunResult } from "@fscrypto/domain/query-run";
import { Inputs, Options } from "@fscrypto/domain/visualization/v3";
import { SortingState, Updater } from "@fscrypto/table";
import { LogoIcon } from "@fscrypto/ui";
import { BigNumber } from "./big-number";
import { Chart } from "./chart";
import { RequirementsNotMet } from "./editor";
import { ResultsTable } from "./table/table";

export const VisualizationRenderer = ({
  input,
  options,
  preview,
  immutable = true,
  dark = false,
  tableProps,
}: {
  input: Inputs;
  options?: Options;
  preview?: boolean;
  immutable?: boolean;
  dark?: boolean;
  tableProps?: TableProps;
}) => {
  if (!options) {
    return (
      <div className="bg-surface flex h-full w-full flex-col items-center justify-center gap-y-2 py-6">
        <LogoIcon className="size-10 opacity-40" />
        <span className="text-muted-foreground text-sm">Run a query to build a visualization</span>
      </div>
    );
  }
  // Check if all requirements are met before rendering the visualization
  const { areRequirementsMet, requirementsStatus } = visualization.v3.hasAllRequiredFields(input);
  if (!areRequirementsMet) return <RequirementsNotMet requirementsStatus={requirementsStatus} type={input.type} />;

  if (input.type === "viz-table") {
    if (!options.table?.resultsRetrieved) return null;
    if (!tableProps) return null;
    return (
      <PreviewContainer isPreview={preview}>
        <ResultsTable
          results={options.table as QueryRunResult}
          sorting={tableProps.state.sorting}
          toggleTransposed={() => tableProps.setState({ transposed: !tableProps.state.transposed })}
          onSortingChange={tableProps.setSorting}
          onFilterChange={(filter) => tableProps.setState({ filter })}
          filter={tableProps.state.filter}
          tableId={tableProps.tableId}
          pageIndex={tableProps.state.pageIndex}
          onPageIndexChange={(pageIndex) => tableProps.setState({ pageIndex })}
          theme={dark ? "dark" : "light"}
        />
      </PreviewContainer>
    );
  }

  if (input.type === "big-number") {
    return (
      <PreviewContainer isPreview={preview}>
        <BigNumber options={options} />
      </PreviewContainer>
    );
  }
  return (
    <div className="h-full w-full">
      {input && options ? (
        <Chart
          constructorType={visualization.v3.chartConstructorType(input)}
          options={options}
          immutable={immutable}
          dark={dark}
        />
      ) : (
        <ChartPlaceholder />
      )}
    </div>
  );
};

const PreviewContainer = ({ isPreview, children }: { isPreview?: boolean; children: React.ReactNode }) => {
  if (isPreview) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="h-[540px] w-[800px] overflow-hidden rounded-lg border shadow-lg">{children}</div>
      </div>
    );
  }
  return <>{children}</>;
};

export const ChartPlaceholder = () => {
  return <div className="flex h-full w-full items-center justify-center">Chart</div>;
};

export interface TableState {
  transposed: boolean;
  sorting: SortingState;
  pageIndex: number;
  filter: string;
}

export interface TableProps {
  state: TableState;
  setState: (state: Partial<TableState>) => void;
  setSorting: (sorting: SortingState | Updater<SortingState>) => void;
  tableId: string;
}
