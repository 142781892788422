import { createFromType } from "./create";
import { BarLineInputs, PlotOptions, Visualization, VisualizationType } from "./schema";

export class ChartTypeConverter {
  private convertFromVis: Visualization;
  private convertFromType: VisualizationType;

  constructor(convertFromVis: Visualization) {
    this.convertFromVis = structuredClone(convertFromVis);
    this.convertFromType = convertFromVis.config.inputs.type;
  }

  // convert the current chart type to a new chart type
  public convert(chartType: VisualizationType): Visualization {
    if (!ChartTypeConverter.getValidChartTypes(this.convertFromType).includes(chartType)) {
      throw new Error(`Invalid chart type conversion from ${this.convertFromType} to ${chartType}`);
    }

    // set the new chart type
    this.convertFromVis.config.inputs.type = chartType;

    // set plot options based on the new chart type
    const convertToConfig = createFromType(chartType);
    const convertFromPlotOptions = this.convertFromVis.config.options.plotOptions;
    const convertToPlotOptions = convertToConfig.options.plotOptions;

    this.convertFromVis.config.options.plotOptions = this.convertPlotOptions(
      convertFromPlotOptions,
      convertToPlotOptions,
    );

    // remove line key from config if converting from bar-line
    if (this.convertFromType === "bar-line") {
      const inputs = this.convertFromVis.config.inputs as BarLineInputs;
      delete inputs.config.line;
    }

    return this.convertFromVis;
  }

  private convertPlotOptions(existingOptions?: PlotOptions, newOptions?: PlotOptions): PlotOptions {
    type PlotOptionsChild = PlotOptions["area"] | PlotOptions["bar"] | PlotOptions["column"];
    const options: PlotOptions = structuredClone(existingOptions) ?? {};

    // if no new options or the new options are empty, clean and return the current options
    if (!newOptions || Object.keys(newOptions).length === 0) {
      return this.cleanOptions(options);
    }

    const isObject = (obj: unknown): obj is Record<string, unknown> => {
      return typeof obj === "object" && obj !== null;
    };

    // merge new options into existing options for a specific key
    const mergeOptions = (key: keyof PlotOptions) => {
      const newOption = newOptions[key] as PlotOptionsChild;
      let option = options[key] as PlotOptionsChild;

      if (isObject(newOption)) {
        if (!option) {
          option = {} as PlotOptionsChild;
        }
        if (isObject(option)) {
          option = { ...option, ...newOption };
        }

        if ("stacking" in newOption) {
          if (newOption.stacking === undefined) {
            delete option?.stacking;
          } else {
            (option as { stacking?: string }).stacking = newOption.stacking;
          }
        } else {
          delete option?.stacking;
        }
      }
      options[key] = option;
    };

    // merge options for a specific key and clean other keys
    const cleanAndMerge = (key: keyof PlotOptions, cleanKeys: (keyof PlotOptions)[]) => {
      mergeOptions(key);
      cleanKeys.forEach((k) => {
        if (k !== key) delete options[k];
      });
      return options;
    };
    // check which new option exists and merge accordingly, cleaning up other keys
    if (newOptions.area) return cleanAndMerge("area", ["bar", "column"]);
    if (newOptions.bar) return cleanAndMerge("bar", ["column", "area"]);
    if (newOptions.column) return cleanAndMerge("column", ["bar", "area"]);

    return options;
  }

  // clean specific keys ('bar', 'column', 'area') from the options
  private cleanOptions(options: PlotOptions) {
    ["bar", "column", "area"].forEach((key) => {
      if (options[key]) delete options[key];
    });
    return options;
  }

  // get valid chart types that can be converted from the current chart type
  static getValidChartTypes(chartType: VisualizationType): VisualizationType[] {
    const areaTypes: VisualizationType[] = ["area", "area-normalized", "area-stacked"];
    const barTypes: VisualizationType[] = [
      "bar",
      "bar-normalized",
      "bar-horizontal",
      "bar-stacked",
      "bar-horizontal-normalized",
      "bar-horizontal-stacked",
    ];
    const lineTypes: VisualizationType[] = ["line"];

    switch (chartType) {
      case "pie":
      case "big-number":
      case "scatter":
      case "viz-table":
        return [];
      case "line":
        return barTypes.concat(areaTypes);
      case "area":
      case "area-normalized":
      case "area-stacked":
        return areaTypes
          .concat(barTypes)
          .concat(lineTypes)
          .filter((type) => type !== chartType);
      case "bar":
      case "bar-normalized":
      case "bar-horizontal":
      case "bar-stacked":
      case "bar-horizontal-normalized":
      case "bar-horizontal-stacked":
      case "bar-line":
        return barTypes.filter((type) => type !== chartType);
      default:
        return [];
    }
  }
}
