import { VizIcon } from "../icons/viz-icon";
import { RequirementsStatus, visualizationTypeLabels, VisualizationType } from "@fscrypto/domain/visualization/v3";
import { Text } from "@fscrypto/ui";
import { CircleCheckIcon, CircleXIcon, TriangleAlert } from "lucide-react";

export const RequirementsNotMet = ({
  requirementsStatus,
  type,
}: {
  requirementsStatus: RequirementsStatus;
  type: VisualizationType;
}) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="bg-background flex flex-col gap-y-4 rounded-lg border p-4">
        <div className="flex items-center gap-x-3">
          <TriangleAlert className="text-warning size-5" />
          <Text className="text-content/80 text-lg font-semibold">{visualizationTypeLabels[type]} Requirements:</Text>
        </div>
        <div className="size-full flex flex-1 items-center">
          <VizIcon type={type} isActive={true} size="xxl" />
          <div className="my-4 flex flex-col gap-y-3 p-4">
            {requirementsStatus.map((r, i) => {
              return (
                <div key={i} className="flex items-center gap-x-4">
                  {r.met ? (
                    <CircleCheckIcon className="size-6 text-success" />
                  ) : (
                    <CircleXIcon className="size-6 text-warning" />
                  )}
                  <Text color="muted">{r.message}</Text>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
