import * as R from "remeda";
import { visualization } from "@fscrypto/domain";
import { ColorPicker } from "@fscrypto/ui";
import { colors } from "@fscrypto/viz-2";

interface ColorFormattingProps {
  dataKeys: string[];
  viz: visualization.v3.Visualization;
  onChange: (viz: visualization.v3.Visualization) => void;
}

export const ColorFormatting = ({ dataKeys, viz, onChange }: ColorFormattingProps) => {
  const values = viz.config.inputs.config.colors
    ? viz.config.inputs.config.colors
    : R.fromKeys(dataKeys, (_, i) => colors.categorical[i]);

  const handleColorChange = (color: string, key: string) => {
    const updatedViz = visualization.v3.updateColors(viz, key, color);
    onChange(updatedViz);
  };

  return (
    <div className="flex flex-col gap-y-2">
      {dataKeys.map((d) => (
        <div key={d} className="flex items-center gap-x-2">
          <ColorPicker value={values[d] as string} onChange={(c) => handleColorChange(c, d)} />
          <span className="truncate">{d}</span>
        </div>
      ))}
    </div>
  );
};
