import { visualization } from "@fscrypto/domain";
import { Card, Input, Switch, Text } from "@fscrypto/ui";
import { HelpCircleIcon } from "lucide-react";
import { FieldSelector } from "../FieldSelector";

interface BigNumberFieldsProps {
  fields: visualization.v3.ColumnTypePair[];
  viz: visualization.v3.Visualization;
  onChange: (i: visualization.v3.Visualization) => void;
  options: visualization.v3.Options;
}

export const BigNumberFields = ({ fields, viz, onChange, options }: BigNumberFieldsProps) => {
  if (!visualization.v3.isBigNumberInput(viz.config.inputs)) return null;
  const config = viz.config.inputs.config;
  const maxRows = options.bigNumber?.rows ?? 0;
  const handleValueChange = (valueKey?: string) => {
    const newViz = visualization.v3.updateBigNumberValue(viz, "valueKey", valueKey);
    onChange(newViz);
  };

  const handleRowNumberChange = (rowNumber: number) => {
    if (rowNumber > maxRows || rowNumber < 1) return;
    const newViz = visualization.v3.updateBigNumberValue(viz, "rowNumber", rowNumber);
    onChange(newViz);
  };

  const handleDecimalsChange = (decimals: number) => {
    const newViz = visualization.v3.updateBigNumberValue(viz, "decimals", decimals);
    onChange(newViz);
  };

  const handleCaptionChange = (caption: string) => {
    const newViz = visualization.v3.updateBigNumberValue(viz, "caption", caption);
    onChange(newViz);
  };

  const handlePrefixChange = (prefix: string) => {
    const newViz = visualization.v3.updateBigNumberValue(viz, "prefix", prefix);
    onChange(newViz);
  };

  const handleSuffixChange = (suffix: string) => {
    const newViz = visualization.v3.updateBigNumberValue(viz, "suffix", suffix);
    onChange(newViz);
  };

  const handleAutoFormatChange = (autoFormat: boolean) => {
    const newViz = visualization.v3.updateBigNumberValue(viz, "autoFormat", autoFormat);
    onChange(newViz);
  };

  const handled3FormatChange = (d3Format: string) => {
    const newViz = visualization.v3.updateBigNumberValue(viz, "d3Format", d3Format);
    onChange(newViz);
  };

  return (
    <>
      <Card className="space-y-2 p-1 px-2">
        <div>
          <Text size="xs" weight="semi">
            Primary Number Field
          </Text>
          <FieldSelector
            fields={fields}
            placeholder="select a field"
            onChange={(field, remove) => handleValueChange(remove ? undefined : field?.name)}
            value={config?.valueKey}
          />
        </div>
        <div>
          <Text size="xs" weight="semi">
            Row Number
          </Text>
          <Input
            type="number"
            value={config?.rowNumber ?? 1}
            onChange={(e) => handleRowNumberChange(Number(e.target.value))}
          />
        </div>
        <div>
          <Text size="xs" weight="semi">
            Decimals
          </Text>
          <Input
            type="number"
            value={config?.decimals ?? 1}
            onChange={(e) => handleDecimalsChange(Number(e.target.value))}
          />
        </div>
      </Card>
      <Card className="space-y-2 p-1 px-2">
        <Text size="xs" weight="semi">
          Caption
        </Text>
        <Input
          placeholder="Add Caption"
          onChange={(e) => handleCaptionChange(e.target.value)}
          value={config?.caption}
        />
        <Text size="xs" weight="semi">
          Prefix
        </Text>
        <Input placeholder="Add Prefix" onChange={(e) => handlePrefixChange(e.target.value)} value={config?.prefix} />
        <Text size="xs" weight="semi">
          Suffix
        </Text>
        <Input placeholder="Add Suffix" onChange={(e) => handleSuffixChange(e.target.value)} value={config?.suffix} />
      </Card>
      <Card className="space-y-2 p-1 px-2">
        <Text size="xs" weight="semi">
          Auto Format
        </Text>
        <div>
          <Switch checked={config?.autoFormat} onCheckedChange={handleAutoFormatChange} />
        </div>

        <div className="flex space-x-2">
          <Text size="xs" weight="semi">
            d3 Format
          </Text>
          <a href="https://observablehq.com/@d3/d3-format" target="_blank" rel="noreferrer">
            <HelpCircleIcon className="size-4 text-muted-foreground" />
          </a>
        </div>
        <Input
          disabled={config?.autoFormat}
          placeholder="Add d3 Format"
          onChange={(e) => handled3FormatChange(e.target.value)}
          value={config?.d3Format}
        />
      </Card>
    </>
  );
};
