import { queryRun, visualization } from "@fscrypto/domain";
import invariant from "tiny-invariant";
import { BigNumberOptionsBuilder } from "./big-number";
import { PieOptionsBuilder } from "./pie";
import { TableOptionsBuilder } from "./table";
import { createFields, stringifyUnsupportedKeys } from "./util";
import {
  AreaOptionsBuilder,
  BarLineOptionsBuilder,
  BarOptionsBuilder,
  HistogramOptionsBuilder,
  LineOptionsBuilder,
  ScatterOptionsBuilder,
} from "./xy";
import { CandlestickOptionsBuilder } from "./xy/candlestick";

export const optionsBuilder = (
  { inputs, options }: visualization.v3.Visualization["config"],
  r: queryRun.QueryRunResult,
): [Highcharts.Options, string[]] => {
  const unsupportedKeys = createFields(r)
    .filter((f) => !visualization.v3.isSupportedDataType(f.type))
    .map((f) => f.name);
  const transformedData =
    unsupportedKeys.length > 0 ? stringifyUnsupportedKeys(r.jsonData, unsupportedKeys) : r.jsonData;
  if (!inputs) return [options, [] as string[]];
  if (visualization.v3.isBar(inputs)) {
    const builder = new BarOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  if (inputs.type === "bar-line") {
    const builder = new BarLineOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  if (visualization.v3.isCandlestick(inputs)) {
    const builder = new CandlestickOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  if (visualization.v3.isLine(inputs)) {
    const builder = new LineOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  if (visualization.v3.isArea(inputs)) {
    const builder = new AreaOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  if (inputs.type === "scatter") {
    const builder = new ScatterOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  if (inputs.type === "pie") {
    const builder = new PieOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  if (inputs.type === "viz-table") {
    const builder = new TableOptionsBuilder(inputs, options);
    const data = builder.getData(r);
    const dataKeys = builder.getDataKeys();
    return [builder.build(data), dataKeys];
  }

  if (inputs.type === "big-number") {
    const builder = new BigNumberOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  if (inputs.type === "histogram") {
    const builder = new HistogramOptionsBuilder(inputs, options);
    const data = builder.getData(transformedData);
    const dataKeys = builder.getDataKeys(data);
    return [builder.build(data), dataKeys];
  }

  invariant(false, "Unknown visualization type");
};
